import { Checkbox } from 'primereact/checkbox';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import React, { useRef, useState } from 'react';
import ApiServices from '../../../../service/apiServices';
import { Button } from 'primereact/button';
import { ConfirmPopup } from 'primereact/confirmpopup';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import Datos from '../../../../refactor/PagoFolio/PagoResulFolio/Folio/DatosGenerales/SubComponentes/Datos';
import Solicitador from '../../../../refactor/PagoFolio/PagoResulFolio/Folio/DatosGenerales/SubComponentes/Solicitador';

const ResulGenerales = (props: any) => {
    const toast = useRef<any>(null);

    const IconDocumentTemplate = (rowData: any) => {
        return (
            <>
                <div className='text-center'>
                    <i className='pi pi-file' style={{ 'fontSize': '2em' }}></i>
                </div>
            </>
        );
    }

    const [visible, setVisible] = useState(false);
    const buttonEl = useRef<any>(null);
    const [selectedFile, setSelectedFile] = useState<any>()

    const accept = () => {
        if (selectedFile) {
            handleDeleteDocument(selectedFile.trm_id, selectedFile.trmd_id)
        }
    };

    const EliminarTemplate = (rowData: any) => {
        return (
            <>
                <div>
                    <Button
                        tooltip="Eliminar archivo subido"
                        className="p-button-text p-button-danger"
                        icon="pi pi-trash"
                        ref={buttonEl} onClick={() => {
                            setVisible(true)
                            setSelectedFile(rowData)
                        }}
                    />
                </div>
            </>
        );
    }

    const [file, setFile] = useState<any>();
    const refFile: any = useRef()
    const [fileName, setFileName] = useState('')

    const handleFileClick = (e: any) => {
        refFile.current.click()
    }

    function esMayorQue5MB(bytes: any) {
        const megabytes = bytes / (1024 * 1024); // Convertir a megabytes
        return megabytes > 5;
    }

    const readUploadFile = (e: any) => {
        e.preventDefault();
        if (e.target.files) {
            if (e.target.files[0].type == 'application/pdf') {
                if (esMayorQue5MB(e.target.files[0].size)) {
                    props.fileToBig()
                } else {
                    setFile(e.target.files[0]);
                }
            }
            else
                toast.current.show({ severity: 'error', summary: 'No es PDF', detail: 'El Archivo no cumple con el formato PDF', life: 3000 });
        }
    }

    const reset = () => {
        /* refFile.current.value = null; */
        setFile(null)
    };

    const handleUploadDocument = () => {
        const trm_id = (props && props.preTramite) ? props.preTramiteDetail.tramite.ptrm_id : props.exist.trm_id
        const data = {
            "trm_id": trm_id,
            "trmd_desc": fileName || file.name,
            "trmd_url": "media/doc/"
        }

        ApiServices.apiPostFile('addDoc', file, data).then(async res => {
            if (res.data.valido === 1) {
                props.requestPreTramiteDetail()
                setFileName('')
                setFile(null)
            }
        }).catch(error => {
            console.log(error)
        })
    }

    const handleDeleteDocument = (id: any, ptrm: any) => {
        const data = {
            "trm_id": id,
            "trmd_id": ptrm,
        }

        ApiServices.apiPost('deleteDoc', data).then(async res => {
            if (res.data.valido === 1) {
                props.requestPreTramiteDetail()
            }
        }).catch(error => {
            console.log(error)
        })
    }

    const notarioTemplate = (option: any) => {
        if (option)
            return (
                <div className="flex align-items-center">
                    <div>{option ? `${option.not_fedata || ''} - ${option.not_nom || ''} ${option.not_pat || ''} ${option.not_mat || ''}` : ''}</div>
                </div>
            );
        else
            return (
                <p>
                    Seleccionar Solcitante
                </p>
            )
    };

    const [size, setSize] = useState(0)

    const seleccionarTitular = () => {
        props.setRequestName(props.titulares ? `${props.titulares[size].ptit_nom} ${props.titulares[size].ptit_pat} ${props.titulares[size].ptit_mat}` : props.requestName)
        const lenghtSize = props.titulares ? props.titulares.length : 0
        const selectedIndex = (size + 1) % lenghtSize;
        setSize(selectedIndex)
    }

    return (
        <section className=''>
            <Toast ref={toast} />


            <Datos data={{
                folio: props.folioDatos.prop_folio,
                claveCatastral: props.folioDatos.prop_clave,
                antecedentes: props.folioDatos.prop_antecedente,
                domicilioRegistral: props.folioDatos.prop_direccion,
                calle: props.folioDatos.prop_calle,
                numeroExterior: props.folioDatos.prop_ext,
                numeroInterior: props.folioDatos.prop_int,
                colonia: props.folioDatos.cp_col,
                municipio: props.folioDatos.cp_mun,
                estado: props.folioDatos.cp_edo,
                localidad: props.folioDatos.cp_loc,
                codigoPostal: props.folioDatos.cp_cp,
                indiviso: props.folioDatos.prop_indiviso,
                usoSuelo: props.folioDatos.prop_ususuelo,
                superificieTotal: props.folioDatos.prop_suptotal,
                restante: props.folioDatos.prop_suprestante,
                construidas: props.folioDatos.prop_constr,
                medidasColindancias: props.folioDatos.prop_medidas
            }} />

            <Solicitador
                data={{
                    view: true,
                    solicitador: props.requestName,
                    notariaSeleccionada: props.selectedNotaryList,
                    catalogoNotarias: props.notaryList,
                    numeroEscritura: props.escrituraNumber,
                    titulares: props.titulares
                }}
                setSolicitador={props.setRequestName}
                setNotariaSeleccionada={props.setSelectedNotaryList}
                setNumeroEscritura={props.setEscrituraNumber}
                seleccionarTitular={seleccionarTitular}
            />

            <div className='grid'>
               
                {props.exist &&
                    <div className='col-12 md:col-12'>
                        <h5>Documentación</h5>
                        <p>Agrega los documentos necesarios para tu tramite</p>
                        <div className='grid'>
                            {
                                props.preTramiteDetail && props.preTramiteDetail.docs &&
                                <div className='col-12 md:col-12'>
                                    <ConfirmPopup
                                        target={buttonEl.current}
                                        visible={visible}
                                        onHide={() => setVisible(false)}
                                        message="¿Estas seguro de eliminar el archivo?"
                                        icon="pi pi-exclamation-triangle"
                                        accept={accept}
                                        acceptLabel='Aceptar'
                                        rejectLabel='Cancelar'
                                    />
                                    <DataTable value={props.preTramiteDetail.docs}
                                        dataKey="id" className="datatable-responsive" scrollable scrollHeight="400px"
                                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} products"
                                        emptyMessage="Sin Documentos" responsiveLayout="scroll">
                                        <Column field="code" body={IconDocumentTemplate} ></Column>
                                        <Column field="trmd_desc" header="Nombre del documento"></Column>
                                        <Column field="name" style={{ width: '10rem' }} body={EliminarTemplate} ></Column>
                                    </DataTable>
                                </div>
                            }
                        </div>

                    </div>
                }
            </div>
        </section>
    );

}

export default React.memo(ResulGenerales);