import { Divider } from 'primereact/divider';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { memo } from 'react';

interface DatosJson {
    solicitador: string;
    notariaSeleccionada: string;
    catalogoNotarias: Array<any>;
    numeroEscritura: string;
    titulares: boolean,
    view: boolean
}

interface DatosProps {
    data: DatosJson;
    setSolicitador: (value: string) => void;
    seleccionarTitular: () => void;
    setNotariaSeleccionada: (value: string) => void;
    setNumeroEscritura: (value: string) => void;
}

const Solicitador = (props: DatosProps) => {

    const classSolicitadoresTitulares = props.data.titulares && !props.data.view ? 'col-12 md:col-5' : 'col-12 md:col-6'

    const notarioTemplate = (option: any) => {
        return (
            <div className="flex align-items-center">
                <div>{option ? `${option.not_num || ''} - ${option.not_nom || ''} ${option.not_pat || ''} ${option.not_mat || ''}` : ''}</div>
            </div>
        );
    };

    return (
        <section>
            <Divider align="left">
                <div className="inline-flex align-items-center">
                    <b>Solicitador</b>
                </div>
            </Divider>
            <div className='grid'>
                <div className='col-12 md:col-12'>
                    <div className='grid'>
                        <div className={classSolicitadoresTitulares}>
                            <span className="p-float-label">
                                <InputText
                                    className="p-inputtext-lg solicitador-input-text"
                                    placeholder="Nombre del Solicitante"
                                    value={props.data.solicitador}
                                    onChange={(e) => { props.setSolicitador(e.target.value) }}
                                    disabled={props.data.view}
                                />
                                <label>Nombre del solicitante</label>
                            </span>
                        </div>
                        {props.data.titulares && !props.data.view &&
                            <div className='col-12 md:col-2'>
                                <div
                                    className='solicitador-button'
                                    onClick={() => { props.seleccionarTitular() }}>
                                    <i className="pi pi-clone"></i>
                                    <p>¿Es Dueño?</p>
                                </div>
                            </div>
                        }
                        <div className={classSolicitadoresTitulares}>
                            <span className="p-float-label">
                                <Dropdown
                                    className="p-inputtext-lg solicitador-notarios-dropdown"
                                    filter
                                    filterBy="not_num,not_fedata,not_nom,not_pat,not_mat,nombre_notario"
                                    itemTemplate={notarioTemplate}
                                    showClear
                                    showFilterClear
                                    onChange={(e) => {
                                        props.setNotariaSeleccionada(e.value)
                                    }}
                                    disabled={props.data.view}
                                    options={props.data.catalogoNotarias}
                                    optionLabel="not_num"
                                    value={props.data.notariaSeleccionada}
                                    valueTemplate={notarioTemplate}
                                    virtualScrollerOptions={{ disabled: false, scrollHeight: '150', itemSize: 40 }}
                                />
                                <label>Notario</label>
                            </span>
                        </div>
                        <div className='col-12 md:col-12'>
                            <span className="p-float-label">
                                <InputText
                                    disabled={props.data.view}
                                    className="p-inputtext-lg"
                                    value={props.data.numeroEscritura}
                                    style={{ color: '#000000' }}
                                    onChange={(e) => { props.setNumeroEscritura(e.target.value) }}
                                />
                                <label htmlFor="username">Numero de Escritura</label>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default memo(Solicitador);